@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* 
*
*
Application Colours Configurations 
*
*
*/

:root {
  --dark: rgba(0, 0, 0, 1);
  --grey: #505f79;
  --grey2: #a8afbc;
  --grey3: #e3e3e3;
  --grey4: #7a869a;
  --grey5: #061938;
  --secondaryTheme: #f7f7f8;
  --selectBackground: rgba(243, 245, 247, 1);
  --tasksText: #332620;
  --light: rgba(255, 255, 255, 1);
  --primaryTheme: #07588f;
  --appTheme: #fff;
  --formBorderColor: rgba(0, 0, 0, 0.2);
  --loginBtn: rgba(7, 88, 143, 1);
}

/* 
*
*
Application Basic Configurations 
*
*
*/

body {
  margin: 0;
  font-family: "Red Hat Text", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--appTheme) !important;
  letter-spacing: 0.03em;
}

body::-webkit-scrollbar {
  display: none !important;
}

.scroll-none::-webkit-scrollbar {
  display: none;
}

/* 
*
*
Application Components 
*
*
*/

.PageContainer {
  padding: 28px 40px;
}

.link-pointer {
  cursor: pointer !important;
}

@media only screen and (max-width: 900px) {
  .PageContainer {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .PageContainer {
    padding: 10px;
  }
}

.common-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
}

.divider {
  background: linear-gradient(to left, rgba(224, 87, 23, 0) 1.11%, var(--primaryTheme) 100%);
  height: 1.5px;
  width: 100%;
  opacity: 0.4;
}

.object-fit {
  object-fit: cover;
}

.scroller-section {
  overflow-y: scroll;
}

.scroller-section::-webkit-scrollbar {
  width: 7px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.scroller-section::-webkit-scrollbar-button {
  display: none;
}

.scroller-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(27, 27, 27, 0.3);
}

/* 
*
*
Application Typography 
*
*
*/

/* 
*
*
white fonts
*
* 
*/

.font-11-white-thin {
  font-size: 11px;
  color: #fff;
  font-weight: 400;
}

.font-12-white-thin {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

.font-12-white-normal {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.font-14-white-bold {
  font-size: 14px;
  color: #fff;
  font-weight: 900;
}

.font-26-white-normal {
  font-size: 26px;
  color: #fff;
  font-weight: 600;
}

.font-40-white-normal {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
}

.font-40-white-bold {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
}

/* 
*
*
black fonts
*
* 
*/

/* 11px black fonts */

.font-black-12-normal {
  font-size: 12px;
  color: var(--dark);
}

/* 14px black fonts */

.font-black-14-normal {
  font-size: 14px;
  color: var(--dark);
}

.font-black-14-bold {
  font-size: 14px;
  color: var(--dark) !important;
  font-weight: bold;
}

.font-black-16-normal {
  font-size: 16px;
  color: var(--dark);
  font-weight: 600;
}

.font-black-16-thin {
  font-size: 16px;
  color: var(--dark);
  font-weight: 100;
}

.font-black-12-normal {
  font-size: 12px;
  color: var(--dark);
}

.font-black-12-bold {
  font-size: 12px;
  color: var(--dark);
  font-weight: bold;
}

.font-black-12-bolder {
  font-size: 12px;
  color: var(--dark);
  font-weight: bolder;
}

.font-black-14-thin {
  font-size: 14px;
  color: var(--dark);
  font-weight: 100;
}

.font-black-14-normal {
  font-size: 14px;
  color: var(--dark);
}

.font-black-14-bold {
  font-size: 14px;
  color: var(--dark);
  font-weight: bold;
}

.font-black-16-normal {
  font-size: 16px;
  color: var(--dark);
}

.font-black-16-thin {
  font-size: 16px;
  color: var(--dark);
  font-weight: 100;
}

.font-black-16-bold {
  font-size: 16px;
  color: var(--dark);
  font-weight: bold;
}

/* 18px black fonts */

.font-black-18-normal {
  font-size: 18px;
  color: var(--dark);
}

.font-black-18-bold {
  font-size: 18px;
  color: var(--dark);
  font-weight: bold;
}

.font-black-40-bold {
  font-size: 40px;
  color: var(--dark);
  font-weight: bold;
}

/* 20px black fonts */

.font-black-20-bold {
  font-size: 20px;
  color: var(--dark);
  font-weight: bold;
}

/* 24px black fonts */

.font-black-24-bold {
  font-size: 24px;
  color: var(--dark);
  font-weight: 600;
}

.font-black-24-normal {
  font-size: 24px;
  color: var(--dark);
  font-weight: 500;
}

.font-black-24-superbold {
  font-size: 24px;
  color: var(--dark);
  font-weight: bolder;
}

.font-black-32-bold {
  font-size: 32px;
  color: var(--dark);
  font-weight: 900;
}

/* 36px black fonts */

.font-black-36-bold {
  font-size: 36px;
  color: var(--dark);
  font-weight: bold;
}

/* 40px black fonts */

.font-black-40-bold {
  font-size: 40px;
  color: var(--dark);
  font-weight: bold;
}

/* 
*
*
Grey fonts
*
* 
*/

/* 10px grey fonts */

.font-grey-10-normal {
  font-size: 10px;
  color: var(--grey);
}

/* 11px grey fonts */

.font-grey-11-normal {
  font-size: 11px;
  color: var(--grey);
}

/* 12px grey fonts */

.font-grey-12-normal {
  font-size: 12px;
  color: var(--grey);
}

.font-grey-12-bold {
  font-size: 12px;
  color: var(--grey);
  font-weight: bold;
}

/* 14px grey fonts */

.font-grey-14-normal {
  font-size: 14px;
  color: var(--grey);
}

.font-grey-14-thin {
  font-size: 14px;
  color: var(--grey);
}

.font-grey-14-bold {
  font-size: 14px;
  color: var(--grey);
  font-weight: 600;
}

.font-grey-16-thin {
  font-size: 16px;
  color: var(--grey4);
  font-weight: 400;
}

.font-grey-18-thin {
  font-size: 18px;
  color: var(--grey4);
  font-weight: 100;
}

.font-grey-18-bold {
  font-size: 18px;
  color: var(--grey4);
  font-weight: 600;
}

.font-grey-16-normal {
  font-size: 16px;
  color: var(--grey4);
  font-weight: 400;
}

.font-grey-20-normal {
  font-size: 20px;
  color: var(--grey);
  font-weight: 500;
}

.font-grey-20-bold {
  font-size: 20px;
  color: var(--grey);
  font-weight: 700;
}

/* 
*
*
Primary Fonts 
*
*
*/

.font-primary-12-bold {
  font-size: 12px;
  color: var(--primaryTheme);
  font-weight: 600;
}

.font-primary-14-bold {
  font-size: 14px;
  color: var(--primaryTheme);
  font-weight: 600;
}

.font-primary-16-bold {
  font-size: 16px;
  color: var(--primaryTheme);
  font-weight: 600;
}

.font-primary-16-thin {
  font-size: 16px;
  color: var(--primaryTheme);
}

.font-primary-20-bold {
  font-size: 20px;
  color: var(--primaryTheme);
  font-weight: bold;
}

.font-primary-24-bold {
  font-size: 24px;
  color: var(--primaryTheme);
  font-weight: bold;
}

/*
*
*
Buttons
*
*
*/

.primary-text-button {
  background: none;
  border: none;
  color: var(--primaryTheme);
}

.primary-contained-button {
  background: var(--primaryTheme);
  color: #fff;
  border: transparent;
}

.login-primary-contained-button {
  background: var(--loginBtn);
  color: #fff;
  border: transparent;
}

.resend-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: green;
}

.primary-outlined-button {
  background: none;
  border: 1px solid var(--primaryTheme);
  color: var(--primaryTheme);
}

.primary-grey-button {
  color: #fff;
  background-color: #4c4c4c;
  border: none;
}

.primary-white-button {
  background-color: white;
  color: black;
  border: none;
}

.white-button {
  background-color: #fff;
  color: var(--primaryTheme);
  border: none;
}

/*
*
*
Form Fields
*
*
*/

::placeholder {
  color: var(--grey2);
  opacity: 0.9;
  font-weight: 500;
  letter-spacing: 0.01em;
}

select,
input,
.custom-mui {
  height: 50px;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 600;
  background-color: var(--selectBackground) !important;
  width: 100%;
  border: none !important;
  /* padding: 10px; */
  border-radius: 4px;
}

select:focus,
input:focus,
.custom-mui:focus {
  outline: none;
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: var(--selectBackground) !important;
}

.error-field {
  font-size: 12px;
  color: red;
  font-weight: 600;
}

/*
*
*
Other Pages
*
*
*/

.Event-header {
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: cover;
}

.Outer-Header-Container {
  position: sticky;
  top: 0;
  z-index: 9;
}

.FormContainer {
  padding: 30px 0px;
  min-height: 40vh;
  max-height: max-content;
}

.upload-image-container {
  background-color: #f7f8fc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploaded-image {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.dashed-border {
  border: 1px dashed var(--dark);
  cursor: pointer;
  border-radius: 10px;
}

.Events-container {
  height: 742px;
  overflow-y: scroll;
}

.Events-container::-webkit-scrollbar {
  display: none;
}

.enquirecard {
  background-color: rgba(245, 245, 245, 0.9);
  width: 550px;
  height: 640px;
  border-radius: 24px;
  padding: 70px 60px 70px 60px;
}

input[type="date"] {
  text-transform: uppercase !important;
}

.carousel-indicators li {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50%;
  background: #fff;
}

.menu-tabs {
  color: var(--tasksText);
  z-index: 99;
}

.menu-tabs:hover {
  color: var(--primaryTheme);
}

/* 
*
*
DashBoard 
*
*
*/

.event-card-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 60%;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
}

.snack-bar-style {
  position: absolute;
  border-radius: 0 !important;
  z-index: 9999999999;
}

/* 
*
*
Other Pages 
*
*
*/

.category-scroll {
  width: 320px;
  overflow: scroll;
}

.category-scroll::-webkit-scrollbar {
  display: none;
}

.width-max-content {
  width: max-content !important;
}

.width-text {
  width: 400px;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.login-right {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  /* background-position: center !important; */
  /* border-radius: 0px 20px 20px 0px; */
  height: 100vh;
}

.login-left {
  /* background: url(./Assets/EventImgLeft.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login-fields {
  border: 1px solid var(--formBorderColor) !important;
  background-color: #fff !important;
  padding-left: 8px;
}

.stepper-text {
  font-family: "Poppins", sans-serif !important;
}

.attachements-icons-color {
  color: #7a869a !important;
}

/* SIgnup */
.PhoneInputCountry {
  margin: 10px;
}

button:disabled {
  opacity: 0.4;
}
